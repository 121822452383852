import React from "react";
import { Box, Stack } from "@mui/material";
import MainLayout from "../../common/components/layout/MainLayout";

const StudentHomePage = () => {
  return (
    <MainLayout>
      <Stack alignItems={"center"} justifyContent={"center"} height={"100%"}>
        <Box
          component={"img"}
          src="/img/AdminHome.png"
          width={"100%"}
          height={"70vh"}
          sx={{ objectFit: "cover" }}
        />
      </Stack>
    </MainLayout>
  );
}

export default StudentHomePage;
