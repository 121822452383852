import { Box, InputLabel } from "@mui/material";
import {useDispatch} from 'react-redux'
import Divider from '@mui/material/Divider';
import { DataGrid } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react"
import { statusCode } from "../../../../common/contstants/constants";
import { notify } from "../../../../common/utils/helpers/notify.util";
import { getUser } from "../../../../common/utils/helpers/userManager.util";
import { dotDangKyChuyenTiepApi } from "../../../admin/dot-dang-ky-chuyen-tiep";
import dangKyChuyenTiepApi from "../dangKyChuyenTiep.api";
import { tableColumnsConfig } from "../dangKyChuyenTiep.config";
import DangKyChuyenTiepFormView from "./DangKyChuyenTiepFormView";
import { loading, offLoading } from "../../../../redux/features/app/appSlice";
import ConfirmDelete from "../../../../common/components/modal/ConfirmDelete";

const DangKyChuyenTiepCardView = () => {
    //Khai báo
    const dispatch = useDispatch();
    const [columns, setColumns] = useState([]);
    const [datasource, setDatasource] = useState([]);
    const [formData, setFormData] = useState({});
    const [dangKyColumns, setDangKyColumns] = useState([]);
    const [dangKyDatasource, setDangKyDatasource] = useState([]);
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [isOpenDangKy, setIsOpenDangKy] = useState(false);
    //End khao báo
    //Functions
    const handleSelectDotDangKy = useCallback((id) => {
        var data = { ...formData };
        data.dotDangKyId = id;
        setFormData(data);
        setIsOpenDangKy(true);
    }, [formData])
    const handleGoBack = () => {
        setFormData({});
        setIsOpenDangKy(false);
    }
    const handleEditDangKy = (items) => {
        setFormData(items);
        setIsOpenDangKy(true);
    }
    const handleConfirmDeleteDangKy = (items) => {
        setFormData(items);
        setIsOpenDelete(true);
    }
    const handleDeleteDangKy = () => {
        let data = { ...formData };
        dispatch(loading());
        dangKyChuyenTiepApi.delete(data, res => {
            dispatch(offLoading());
            notify(res.status === statusCode.error ? "error" : "success", res.message);
            if (res.status === statusCode.success) {
                setFormData({});
                fetchListDangKy();
                setIsOpenDelete(false);
            }
        })
    }
    const fetchListDotDangKy = useCallback(() =>{

        let columnConfig = tableColumnsConfig.find(x => x.type === "dotdangkyactive");
        let _columns = columnConfig.columns({ handleSelectDotDangKy: handleSelectDotDangKy });
        setColumns(_columns);
        dispatch(loading());
        dotDangKyChuyenTiepApi.fetchDotDangKyActive({}, res => {
            dispatch(offLoading());
            setDatasource(res?.data?.items ?? []);
        })
    }, [handleSelectDotDangKy, dispatch])
    const fetchListDangKy = useCallback(() => {
        let columnCofig = tableColumnsConfig.find(x => x.type === "dangky");
        let _columns = columnCofig.columns({ handleEditDangKy: handleEditDangKy, handleConfirmDeleteDangKy: handleConfirmDeleteDangKy });
        setDangKyColumns(_columns);
        let user = getUser();
        dangKyChuyenTiepApi.fetchList({ sinhVienId: user.userId }, res => {
            setDangKyDatasource(res?.data?.items ?? [])
        })
    }, [])
    //End functions
    //Use effect
    useEffect(() => {
        if (!formData?.dotDangKyId) {
            // danh sách đợt đăng ký
            fetchListDotDangKy();
            // danh sách đã đăng ký
            fetchListDangKy();
        }
    }, [dispatch, formData?.dotDangKyId, fetchListDotDangKy, fetchListDangKy]);
    //End Use effect
    return (
        <>
            {
                formData?.dotDangKyId && isOpenDangKy ?
                    <DangKyChuyenTiepFormView handleGoBack={handleGoBack} _formData={formData}></DangKyChuyenTiepFormView>
                    :
                    <>
                        <InputLabel sx={{ textAlign: "left", fontSize: "20px" }}>Danh sách đợt đăng ký</InputLabel>
                        <Box mt={2} height={"50vh"} width={"100%"}>
                            <DataGrid rows={datasource} columns={columns} />
                        </Box>
                        <Divider />
                        <InputLabel sx={{ textAlign: "left", fontSize: "20px", marginTop: "40px" }}>Chương trình học đã đăng ký</InputLabel>
                        <Box mt={2} height={"50vh"} width={"100%"}>
                            <DataGrid rows={dangKyDatasource} columns={dangKyColumns} />
                        </Box>
                    </>
            }

            <ConfirmDelete
                open={isOpenDelete}
                handleClose={() => setIsOpenDelete(false)}
                handleOk={handleDeleteDangKy}
                content="Bạn chắc chắn muốn xóa đăng ký"
            />
        </>
    )
}
export default DangKyChuyenTiepCardView