import React from "react";
import MainLayout from "../../common/components/layout/MainLayout";
import LoginForm from "../../modules/login/components/LoginForm";

const LoginPage = () => {

  return (
    <MainLayout>
        <LoginForm></LoginForm>
    </MainLayout>
  );
}

export default LoginPage;
