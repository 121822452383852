import React from "react";
import { useSelector } from 'react-redux';
import { Box, Container } from "@mui/material";
import Header from "./header";
import Footer from "./footer";
import PageLoading from "../loading/PageLoading";
import { isLoadingSelector } from "../../../redux/features/app/appSlice";

function MainLayout({ children }) {
  const isLoading = useSelector(isLoadingSelector);
  return (
    <>
      <Header />
      <Box py={4} sx={{ paddingBottom: "100px" }}>
        <Container>{children}</Container>
      </Box>
      <Footer />
      {isLoading ? <PageLoading></PageLoading> : ""}

    </>
  );
}

export default MainLayout;
