import privateClient from "../../../common/utils/api/privateClient";

const nganhEndpoint = {
    fetchListByCTDT: "/api/nganh/list-by-ctdt"
}
export {
    nganhEndpoint
}
const nganhApi = {
    fetchListByCTDT: (params, callback) => {
        privateClient.post(nganhEndpoint.fetchListByCTDT, params)
        .then((res) => {
          if (callback) callback(res);
        })
        .catch((err) => {
          if (callback) callback(err);
        });
    }
}
export default nganhApi