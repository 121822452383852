import publicClient from "../../common/utils/api/publicClient";


const authEndpoint = {
  login: "/connect/token",
  register: "/Authorization/register",
  edit: "/Authorization/Update_Admin",
  deleteAccount: (username) => "/Authorization/DeleteAccount?username=" + username,
  list: "/Authorization/getAccount",
}

const authApi = {
  login: (params, callback) => {
    params.append('grant_type', 'password');
    params.append('client_id', '33C709AE-1737-4073-982C-AD48AE8DC784');
    params.append('client_secret', 'ACAE42A5-0415-4950-B36C-58262542EF70');
    params.append('scope', 'offline_access');
    let headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
    publicClient.post(authEndpoint.login, params.toString(), {
      headers: headers
    })
      .then((res) => {
        if (callback) callback(res);
      })
      .catch((err) => {
        if (callback) callback(err);
      });
  },
  register: async (payload) => {
    await publicClient.post(authEndpoint.register, payload)
  },
  edit: async (payload) => {
    await publicClient.post(authEndpoint.edit, payload);
  },
  deleteAccount: async (username) => {
    await publicClient.post(authEndpoint.deleteAccount(username));
  },
  list: async () => {
    await publicClient.get(authEndpoint.list);
  }
}

export default authApi