import { FileDownload } from "@mui/icons-material";
import { Box, Button, Grid, MenuItem, Select, TextField } from "@mui/material"
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CommonTable from "../../../../common/components/table/CommonTable";
import { loading, offLoading } from "../../../../redux/features/app/appSlice";
import dangKyChuyenTiepApi from "../../../student/dang-ky-chuyen-tiep/dangKyChuyenTiep.api";
import { dotDangKyChuyenTiepApi } from "../../dot-dang-ky-chuyen-tiep";
import { tableColumnsConfig } from "../quanLyDangKy.config";

const QuanLyDangKyCardView = () => {
    //Khai báo
    const dispatch = useDispatch();
    const [columns, setColumns] = useState([]);
    const [datasource, setDatasource] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [filterData, setFilterData] = useState({});
    const [listDotDangKy, setListDotDangKy] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    //End khao báo
    //Functions
    const fetchData = useCallback((params) => {
        params = { ...params ?? {}, ...filterData, pageNumber, pageSize };
        dispatch(loading());
        dangKyChuyenTiepApi.fetchList(params, res => {
            dispatch(offLoading());
            setDatasource(res?.data?.items ?? []);
            let rowCount = res?.data?.totalItems ?? 0;
            setRowCount(rowCount);
        })
    }, [dispatch, filterData, pageNumber, pageSize])

    const hanldeFilterSubmit = (e) => {
        e?.preventDefault();
        // if(pageNumber === 0){
        fetchData();
        // }
        // else{
        //     setPageNumber(0);
        // }
    }

    const handleInputFilterChange = (e) => {
        let name = e.target.name,
            value = e.target.value;
        let data = { ...filterData };
        data[name] = value;
        setFilterData(data);
        setPageNumber(0);
    }

    const handleFilterSelectChange = (e) => {
        let name = e.target.name,
            value = e.target.value;
        switch (name) {
            case "dotDangKyId":
                value = value === '' || value === 0 ? null : value
                break;
            default:
                break;
        }
        let data = { ...filterData };
        data[name] = value;
        setFilterData(data);
        setPageNumber(0);
        // if(pageNumber === 0){
        //     fetchData();
        // }
        // else{
        //     setPageNumber(0);
        // }
    }

    const handleExportExcel = () => {
        dispatch(loading());
        dangKyChuyenTiepApi.exportExcel({ ...filterData }, res => {
            dispatch(offLoading());
        });
    }

    const handlePageChange = (event, newPage,) => {
        setPageNumber(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setPageNumber(0);
        setPageSize(parseInt(event.target.value, 10));
    };
    //End Functions
    //Use effect
    useEffect(() => {
        let columnConfig = tableColumnsConfig.find(x => x.type === "all");
        let _columns = columnConfig.columns();
        setColumns(_columns);
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        dotDangKyChuyenTiepApi.fetchList({}, res => {
            let data = res?.data?.items ?? [];
            if (data.length) {
                data.unshift({ id: 0, tenDotDangKy: "Tất cả" });
            }
            setListDotDangKy(data);
        })
    }, [])

    useEffect(() => {
        setRowCount((prevRowCountState) =>
            rowCount !== undefined
                ? rowCount
                : prevRowCountState,
        );
    }, [rowCount, setRowCount]);
    //End Use effect
    return (
        <>
            <Box component={"form"} onSubmit={hanldeFilterSubmit}>
                <Grid container spacing={2}>
                    <Grid xs={12} sm={12} md={6} xl={5} item>
                        {
                            listDotDangKy.length > 0 ?
                                <Select
                                    fullWidth
                                    size="small"
                                    name="dotDangKyId"
                                    onChange={handleFilterSelectChange}
                                    value={filterData?.dotDangKyId ?? 0}
                                >
                                    {listDotDangKy?.map((e) => {
                                        return (
                                            <MenuItem value={e.id} key={e.id}>
                                                {`${e?.tenDotDangKy}`}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                                : ""
                        }
                    </Grid>
                    <Grid xs={12} sm={12} md={6} xl={5} item>
                        <TextField
                            fullWidth
                            size="small"
                            name="keyword"
                            placeholder="Từ khóa"
                            onChange={handleInputFilterChange}
                        />
                        <Button
                            variant="contained"
                            fullWidth
                            size="medium"
                            sx={{ marginTop: "16px", display: "none" }}
                            type="submit"

                        >
                            ĐĂNG NHẬP
                        </Button>
                    </Grid>
                    <Grid xs={12} sm={12} md={2} xl={2} item>
                        <Button
                            variant="contained"
                            size="medium"
                            color="success"
                            onClick={handleExportExcel}
                        >
                            <FileDownload></FileDownload>
                            Xuất excel
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <CommonTable
                datasource={datasource}
                columns={columns}
                rowCount={rowCount}
                paginationInfo={{ pageSize, pageNumber }}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
            ></CommonTable>
        </>
    )
}
export default QuanLyDangKyCardView