import { Route, Routes } from "react-router-dom";
import "./App.css";
import { listRouter } from "./common/contstants/router";
import { useEffect, useState } from "react";
import NotFoundPage from "./pages/NotFoundPage";
import { getUser } from "./common/utils/helpers/userManager.util";

function App() {
  const [user, setUser] = useState({});
  const [router, setRouter] = useState([]);

  useEffect(() => {
    // const getData = () => {
    //   var userItem = localStorage?.getItem("user") ?? null;
    //   const userLocal = userItem != null && userItem != "undefined" ? JSON.parse(userItem) : null;
    // };
    let userLocal = getUser();
    setUser(userLocal);
  }, []);

  useEffect(() => {
    if (user) {
      user?.role === "Student"
        ? setRouter(listRouter?.student)
        : user?.role === "Staff"
          ? setRouter(listRouter?.staff)
          : setRouter(listRouter?.admin);
    } else {
      setRouter(listRouter?.auth);
    }
  }, [user]);

  return (
    <Routes>
      {router?.map((router, index) => (
        <Route path={router.path} element={router?.element} key={index} />
      ))}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
