import privateClient from "../../../common/utils/api/privateClient";

const dotDangKyChuyenTiepEndpoint = {
    fetchDotDangKyActive: "/api/dot-dang-ky-chuyen-tiep-ctdt/list-active",
    fetchList: "/api/dot-dang-ky-chuyen-tiep-ctdt"
}
export {
    dotDangKyChuyenTiepEndpoint
}
const dotDangKyChuyenTiepApi = {
    fetchDotDangKyActive: (params, callback) => {
        privateClient.post(dotDangKyChuyenTiepEndpoint.fetchDotDangKyActive, params)
        .then((res) => {
          if (callback) callback(res);
        })
        .catch((err) => {
          if (callback) callback(err);
        });
    },
    fetchList: (params, callback) => {
        privateClient.post(dotDangKyChuyenTiepEndpoint.fetchList, params)
        .then((res) => {
          if (callback) callback(res);
        })
        .catch((err) => {
          if (callback) callback(err);
        });
    }
}
export default dotDangKyChuyenTiepApi