import React from "react";
import { Stack } from "@mui/material";
import MainLayout from "../../common/components/layout/MainLayout";

const AdminHomePage = () => {
  return (
    <MainLayout>
      <Stack alignItems={"center"} justifyContent={"center"} height={"100%"}>
      </Stack>
    </MainLayout>
  );
}

export default AdminHomePage;
