import AdminHomePage from "../../pages/admin/AdminHomePage";
import QuanLyDangKyPage from "../../pages/admin/QuanLyDangKyPage";
import LoginPage from "../../pages/auth/LoginPage";
import DangKyChuyenTiepPage from "../../pages/student/DangKyChuyenTiepPage";
import StudentHomePage from "../../pages/student/StudentHomePage";
import AuthComponent from "../components/auth/AuthComponent";

export const listRouter = {
  admin: [
    { path: "/", element: <AuthComponent><AdminHomePage /></AuthComponent> },
    { path: "/quan-ly-dang-ky", element: <AuthComponent><QuanLyDangKyPage /></AuthComponent> },
  ],
  student: [
    { path: "/", element: <AuthComponent><StudentHomePage /></AuthComponent> },
    { path: "/dang-ky-chuyen-tiep", element: <AuthComponent><DangKyChuyenTiepPage /></AuthComponent> },
  ],
  auth: [{ path: "/", element: <LoginPage /> }],
};
