import { Button, ButtonGroup } from "@mui/material"
import { formatDate } from "../../../common/utils/helpers/date.util"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const tableColumnsConfig = [
    {
        type: "dotdangkyactive",
        columns: ({ handleSelectDotDangKy }) => {
            return [
                { field: "stt", headerName: "STT", width: 100, },
                { field: "tenDotDangKy", headerName: "Tên đợt ĐK", width: 350 },
                {
                    field: "tuNgay", headerName: "Từ ngày", width: 150,
                    renderCell: (cell) => {
                        return (
                            formatDate(cell.value, 1)
                        )
                    }
                },
                {
                    field: "denNgay", headerName: "Đến ngày", width: 150,
                    renderCell: (cell) => {
                        return (
                            formatDate(cell.value, 1)
                        )
                    }
                },
                {
                    field: "id", headerName: "Thao tác", width: 150,
                    renderCell: (cell) => {
                        return (
                            <Button size="small" variant="contained" onClick={() => { handleSelectDotDangKy(cell.id) }}>
                                Đăng ký
                            </Button>
                        )
                    }
                },
            ]
        },
    },

    {
        type: "dangky",
        columns: ({ handleEditDangKy, handleConfirmDeleteDangKy }) => {
            return [
                { field: "stt", headerName: "STT", width: 100, },
                { field: "tenChuongTrinh", headerName: "Tên chương trình", width: 350 },
                { field: "tenNganh", headerName: "Tên ngành, chuyên ngành", width: 350 },
                {
                    field: "id", headerName: "Thao tác", width: 150,
                    renderCell: (cell) => {
                        return !cell?.row.isActive ? "" : (
                            <>
                                <ButtonGroup
                                    disableElevation
                                    variant="contained"
                                    aria-label="Disabled button group"
                                >
                                    <Button variant="contained" color="info" onClick={() => { handleEditDangKy(cell.row) }}>
                                        <EditIcon></EditIcon>
                                    </Button>
                                    <Button variant="contained" color="error" onClick={() => { handleConfirmDeleteDangKy(cell.row) }}>
                                        <DeleteIcon></DeleteIcon>
                                    </Button>
                                </ButtonGroup>
                            </>
                        )
                    }
                },
            ]
        },
    }
]
export {
    tableColumnsConfig
}