
const tableColumnsConfig = [
    {
        type: "all",
        columns: () => {
            return [
                {
                    field: "stt", headerName: "STT", width: 50, headerAlign: 'center',
                    align: 'center',
                },
                {
                    field: "maSinhVien", headerName: "Mã SV", width: 100, headerAlign: 'center',
                    align: 'center',
                },
                { field: "hoTen", headerName: "Họ tên", width: 150, headerAlign: 'center' },
                {
                    field: "soDienThoai", headerName: "Điện thoại", headerAlign: 'center',
                    align: 'center',
                },
                {
                    field: "tenLop", headerName: "Lớp", width: 100, headerAlign: 'center',
                    align: 'center',
                },
                { field: "tenKhoaCuNhan", headerName: "Khoa", width: 100, headerAlign: 'center' },
                {
                    field: "maNganhCuNhan", headerName: "Mã ngành, CN", width: 100, headerAlign: 'center',
                    align: 'center',
                },
                { field: "tenNganhCuNhan", headerName: "Ngành, CN", width: 100, headerAlign: 'center' },
                {
                    field: "maNganh", headerName: "Mã ngành CN ĐK", width: 100, headerAlign: 'center',
                    align: 'center',
                },
                { field: "tenNganh", headerName: "Ngành, CN ĐK", width: 100, headerAlign: 'center' },
                { field: "tenKhoa", headerName: "Khoa, CN ĐK", width: 100, headerAlign: 'center' },
                { field: "tenChuongTrinh", headerName: "Tên CTĐT", width: 100, headerAlign: 'center' },
            ]
        },
    },
]
export {
    tableColumnsConfig
}