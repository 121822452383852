import privateClient from "../../../common/utils/api/privateClient";
import { downloadFile } from "../../../common/utils/helpers/fileManage.util";

const dangKyChuyenTiepEndpoint = {
    insert: "api/dang-ky-chuyen-tiep-ctdt/insert",
    update: "api/dang-ky-chuyen-tiep-ctdt/update",
    delete: "api/dang-ky-chuyen-tiep-ctdt/delete",
    fetchList: "api/dang-ky-chuyen-tiep-ctdt",
    exportExcel: "api/dang-ky-chuyen-tiep-ctdt/export-excel"
}
export {
    dangKyChuyenTiepEndpoint
}
const dangKyChuyenTiepApi = {
    insert: (params, callback) => {
        privateClient.post(dangKyChuyenTiepEndpoint.insert, params)
        .then((res) => {
            if(callback) callback(res)
        })
        .catch((err) => {
          if (callback) callback(err);
        });
    },
    update: (params, callback) => {
        privateClient.put(dangKyChuyenTiepEndpoint.update, params)
        .then((res) => {
            if(callback) callback(res)
        })
        .catch((err) => {
          if (callback) callback(err);
        });
    },
    delete: (params, callback) => {
        privateClient.post(dangKyChuyenTiepEndpoint.delete, params)
        .then((res) => {
            if(callback) callback(res)
        })
        .catch((err) => {
          if (callback) callback(err);
        });
    },
    fetchList: (params, callback) => {
        privateClient.post(dangKyChuyenTiepEndpoint.fetchList, params)
        .then((res) => {
            if(callback) callback(res)
        })
        .catch((err) => {
          if (callback) callback(err);
        });
    },
    exportExcel: (params, callback) => {
        downloadFile(dangKyChuyenTiepEndpoint.exportExcel, params, callback);
    }
}
export default dangKyChuyenTiepApi