import { Box, Button, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux"
import { statusCode } from "../../../../common/contstants/constants";
import { notify } from "../../../../common/utils/helpers/notify.util";
import { getUser } from "../../../../common/utils/helpers/userManager.util";
import { loading, offLoading } from "../../../../redux/features/app/appSlice";
import { chuongTrinhDaoTaoApi } from "../../../admin/chuong-trinh-dao-tao";
import { nganhApi } from "../../../admin/nganh";
import dangKyChuyenTiepApi from "../dangKyChuyenTiep.api";
const DangKyChuyenTiepCTDTFormView = ({ handleGoBack, _formData }) => {
    //Khai báo
    const dispatch = useDispatch();
    const [listCTDT, setListCTDT] = useState([]);
    const [listNganh, setListNganh] = useState([]);
    const [formData, setFormData] = useState(_formData ?? {});
    //End khao báo
    //Use effect
    useEffect(() => {
        if (formData?.dotDangKyId) {
            fetchListCTDT();
        }
        else {
            setListCTDT([]);
        }
    }, [formData?.dotDangKyId])

    useEffect(() => {
        if (formData?.chuongTrinhDaoTaoId)
            nganhApi.fetchListByCTDT({ chuongTrinhDaoTaoId: formData?.chuongTrinhDaoTaoId }, res => {
                setListNganh(res?.data.items);
            })
    }, [formData?.chuongTrinhDaoTaoId])
    //End Use effect
    //Functions
    const fetchListCTDT = () => {
        chuongTrinhDaoTaoApi.fetchList({}, res => {
            setListCTDT(res?.data.items);
        })
    }
    const hanldeDangKySubmit = (e) => {
        e.preventDefault();
        let data = { ...formData };
        let user = getUser();
        if (!data.nganhId) {
            notify("error", "Vui lòng chọn ngành");
            return;
        }
        data.sinhVienId = user.userId;
        data.createdBy = user.userId.toString();
        if (!data.id) {
            dispatch(loading());
            dangKyChuyenTiepApi.insert(data, res => {
                dispatch(offLoading());
                notify(res.status === statusCode.error ? "error" : "success", res.message);
                if (res.status === statusCode.success) {
                    handleGoBack();
                }
            })
        }
        else {
            dispatch(loading());
            dangKyChuyenTiepApi.update(data, res => {
                dispatch(offLoading());
                notify(res.status === statusCode.error ? "error" : "success", res.message);
                if (res.status === statusCode.success) {
                    handleGoBack();
                }
            })
        }
    }
    const handleSelectChange = (e) => {
        let name = e.target.name,
            value = e.target.value;
        let data = { ...formData };
        switch (name) {
            case "chuongTrinhDaoTaoId":
                // nganhApi.fetchListByCTDT({ chuongTrinhDaoTaoId: value }, res => {
                //     setListNganh(res?.data.items);
                // })
                data["nganhId"] = null;
                break;
            default:
                break;
        }
        data[name] = value;
        setFormData(data);
    }
    const handleInputChange = (e) => {
        let name = e.target.name,
            value = e.target.value;
        let data = { ...formData };
        data[name] = value;
        setFormData(data);
    }
    //End functions
    return (<>
        <Box mt={4} width={"100%"} >
        </Box>
        <Box mt={2} width={"100%"} component={"form"} onSubmit={hanldeDangKySubmit}>
            <InputLabel id="chuong-trinh-dao-tao-label">Chương trình đào tạo</InputLabel>
            {
                listCTDT.length > 0 ?
                    <Select
                        labelId="chuong-trinh-dao-tao-label"
                        fullWidth
                        size="small"
                        name="chuongTrinhDaoTaoId"
                        value={formData.chuongTrinhDaoTaoId ?? ""}
                        required
                        onChange={handleSelectChange}
                    >
                        {listCTDT?.map((e) => {
                            return (
                                <MenuItem value={e.id} key={e.id}>
                                    {`${e?.maChuongTrinh} - ${e?.tenChuongTrinh}`}
                                </MenuItem>
                            )
                        })}
                    </Select>
                    : ""
            }
            {
                listNganh.length > 0 ?
                    <>
                        <InputLabel mt={4} sx={{ marginTop: "16px" }} id="nganh-label">Danh sách ngành chuyên ngành</InputLabel>
                        <RadioGroup
                            aria-labelledby="nganh-label"
                            name="nganhId"
                            value={formData?.nganhId ?? null}
                        >
                            {
                                listNganh?.map((e) => {
                                    return (
                                        <FormControlLabel name="nganhId" key={e.id} value={e.id}
                                            control={<Radio onChange={handleInputChange} />} label={`${e?.tenNganh}`} />
                                    )
                                })
                            }

                        </RadioGroup>
                    </>
                    : ""
            }

            <Box width={"100%"} sx={{ textAlign: "center" }}>
                <Button
                    variant="contained"
                    size="small"
                    sx={{ marginTop: "16px", marginLeft: "auto", marginRight: "auto" }}
                    type="submit"
                >
                    {formData.id ? "Lưu" : "Đăng ký"}
                </Button>
                <Button size="small" color="secondary" variant="contained" onClick={handleGoBack}
                    type="button"
                    sx={{ marginTop: "16px", marginLeft: "10px", marginRight: "auto" }}
                >
                    Quay lại
                </Button>
            </Box>
        </Box>
    </>)
}
export default DangKyChuyenTiepCTDTFormView