
import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
        sidebarOpen: false,
        isLoading: false
    },
    reducers: {
        changeDimensions: (state) => {
            return {
                ...state,
                winWidth: window.innerWidth,
                winHeight: window.innerHeight
            }
        },
        toggleSidebar: (state, { payload }) => {
            return {
                ...state,
                sidebarOpen: payload
            }
        },
        loading: (state) => {
            return {
                ...state,
                isLoading: true
            }
        },
        offLoading: (state) => {
            return {
                ...state,
                isLoading: false
            }
        }
    }
})
export const { changeDimensions, toggleSidebar, loading, offLoading } = appSlice.actions;

export const sidebarOpenSelector = (state) => state.app.sidebarOpen;
export const isLoadingSelector = (state) => state.app.isLoading;
export const winWidthSelector = (state) => state.app.winWidth;

export default appSlice.reducer;