import { DataGrid } from "@mui/x-data-grid"
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Box, TablePagination } from "@mui/material";

const CommonTable = ({ datasource, columns, rowCount, paginationInfo, handlePageChange, handleRowsPerPageChange }) => {
    return (
        <Box mt={2} width={"100%"}>
            <DataGrid rows={datasource} columns={columns}
                hideFooter={true}
                autoHeight
                localeText={{ noRowsLabel: "Không tìm thấy dữ liệu hiển thị" }}
            />
            <Box >
                <TablePagination sx={{ width: "100vw", border: "none" }}
                    rowsPerPageOptions={[10, 25, 50]}
                    count={rowCount}
                    page={paginationInfo.pageNumber}
                    rowsPerPage={paginationInfo.pageSize}
                    slotProps={{
                        actions: {
                            showFirstButton: true,
                            showLastButton: true,
                            slots: {
                                firstPageIcon: FirstPageRoundedIcon,
                                lastPageIcon: LastPageRoundedIcon,
                                nextPageIcon: ChevronRightRoundedIcon,
                                backPageIcon: ChevronLeftRoundedIcon,
                            },
                        },
                    }}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    labelRowsPerPage={"Số dòng"}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} trên ${count}`}
                />
            </Box>
        </Box>
    )
}
export default CommonTable