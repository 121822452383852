import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

const ConfirmDelete = ({ open, title, content, handleClose, handleOk }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" fontWeight={"bold"}>
      {title || "XÁC NHẬN XÓA"}
        {/* <Typography variant="h6">
        </Typography> */}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
            {content ||
              "Bạn có muốn chắc chắn xóa không? nếu xóa sẽ không thể khôi phục được !"}
          {/* <Typography>
          </Typography> */}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="outlined"
          color="error"
          size="small"
        >
          Không đồng ý
        </Button>
        <Button autoFocus onClick={handleOk} size="small" variant="contained">
          Đồng ý
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDelete;
