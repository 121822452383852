import React from "react";
import Logo from "./Logo";
import Menu from "../menu/Menu";

function Header() {
  return (
    <>
      <Logo />
      <Menu />
    </>
  );
}

export default Header;
