import { Box, Container, Typography } from "@mui/material";
import React from "react";

function Logo() {
  return (
    <Box bgcolor={"#08406f"}>
      <Container>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
          sx={{paddingTop: "10px", paddingBottom: "10px"}}
        >
          <Box display={"flex"} gap={2} alignItems={"center"}>
            <Box component={"img"} src="/img/logo.png" width={70} height={70} />
            <Box>
              <Typography color={"white"} fontWeight={700}>
                TRƯỜNG ĐẠI HỌC XÂY DỰNG HÀ NỘI
              </Typography>
              <Typography color={"white"}>
                Hanoi University of Civil Engineering
              </Typography>
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-end"}
          >
            <Typography color={"yellow"} fontWeight={700}>
              HỆ THỐNG ĐĂNG KÝ CHUYỂN TIẾP LÊN CHƯƠNG TRÌNH KS, KTS, THS
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Logo;
