import React from "react";
import { Stack } from "@mui/material";
import QuanLyDangKyCardView from "../../modules/admin/quan-ly-dang-ky/components/QuanLyDangKyCardView";
import MainLayout from "../../common/components/layout/MainLayout";

function QuanLyDangKyPage() {
  return (
    <MainLayout>
      <Stack height={"100%"}>
        <QuanLyDangKyCardView></QuanLyDangKyCardView>
      </Stack>
    </MainLayout>
  );
}

export default QuanLyDangKyPage;
