import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { notify } from "../../../common/utils/helpers/notify.util";
import jwtDecode from 'jwt-decode';
import authApi from "../auth.api";
import { loading, offLoading } from '../../../redux/features/app/appSlice';

const LoginForm = () => {
    //Khai báo
    const dispatch = useDispatch();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    //End khai báo
    //Functions
    const hanldeLogin = (e) => {
        e.preventDefault();
        let fd = new URLSearchParams();
        fd.append("username", username);
        fd.append("password", password);
        try {
            dispatch(loading());
            authApi.login(fd, res => {
                dispatch(offLoading());
                if (!res.error) {
                    let claims = jwtDecode(res.access_token);
                    var user = {};
                    if (claims.is_sinhvien) {
                        user.userId = claims.sinh_vien_id;
                        user.username = claims.ma_sv;
                        user.name = `${claims.ho_dem_sv} ${claims.ten_sv}`;
                        user.role = "Student";
                        user.access_token = res.access_token;
                        let expirationTime = new Date();
                        user.expirationTime = expirationTime.setSeconds(expirationTime.getSeconds() + res.expires_in);
                    }
                    else {
                        user.sub = claims.sub;
                        user.userId = claims.user_id;
                        user.username = claims.username;
                        user.name = claims.name;
                        user.role = "Admin";
                        user.access_token = res.access_token;
                        let expirationTime = new Date();
                        user.expirationTime = expirationTime.setSeconds(expirationTime.getSeconds() + res.expires_in);
                    }
                    localStorage.setItem("user", JSON.stringify(user));
                    window.location.reload();
                }
                else {
                    notify("error", "Lỗi đăng nhập");
                }
            });
        }
        catch {
            notify("error", "Lỗi đăng nhập");
        }
    }
    //End functions
    return (
        <Box display={"flex"} justifyContent={"center"} mt={4}>
            <Grid container>
                <Grid xs={12} sm={12} md={5} xl={5} mx="auto" item>
                    <Paper elevation={3}>
                        <Box p={4} component={"form"} onSubmit={hanldeLogin}>
                            <Typography variant="h5" textAlign={"center"}>
                                ĐĂNG NHẬP
                            </Typography>
                            <Box mt={4}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Password"
                                    sx={{ marginTop: "16px" }}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    type="password"
                                />
                                <Button
                                    variant="contained"
                                    fullWidth
                                    size="medium"
                                    sx={{ marginTop: "16px" }}
                                    type="submit"
                                >
                                    ĐĂNG NHẬP
                                </Button>
                            </Box>
                            <Box mt={4}>
                                <Typography variant="subtitle2" fontStyle={"italic"}>
                                    Chú ý:
                                </Typography>
                                <Typography variant="subtitle2" fontStyle={"italic"}>
                                    1. Sinh viên sử dụng tài khoản trên cổng thông tin sinh viên để đăng nhập vào hệ thống.
                                </Typography>
                                <Typography variant="subtitle2" fontStyle={"italic"}>
                                    2. Nếu gặp khó khăn khi đăng nhập, sinh viên liên hệ email:
                                    daotao@huce.edu.vn. Hotline: 096258435
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}
export default LoginForm