import axios from "axios";
import { getUser } from "../helpers/userManager.util";
import queryString from 'query-string';
const baseURL = "https://rms-api.huce.edu.vn";

const privateBlobClient = axios.create({
    baseURL,
    responseType: 'blob',
    paramsSerializer: {
        encode: params => queryString.stringify(params)
    }
})

privateBlobClient.interceptors.request.use(async config => {
    let signedInUser = getUser();
    let _config = { ...config };
    _config.headers["Content-Type"] = "application/json-patch+json";
    _config.headers.Authorization = `Bearer ${signedInUser?.access_token}`;
    return _config;
})

privateBlobClient.interceptors.response.use(response => {
    return response;
}, error => {
    throw error.response;
});

export default privateBlobClient;