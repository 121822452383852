import privateBlobClient from "../api/privateBlobClient";
import { notify } from "./notify.util";

const downloadFile = (url, params, callback) => {
    privateBlobClient.post(url, params).then((response) => {
        if (callback) callback(response);

        let filename = '';
        var disposition = response.headers['content-disposition'];
        if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
        }

        if (response.data.size > 0) {
            // create file link in browser's memory
            const href = window.URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', decodeURIComponent(filename)); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }
        else {
            notify("error", filename);
        }
    }).catch(error => {
        notify("error", "Không thể tải tệp tin");
        if (callback) callback();
    });
}

export {
    downloadFile
}