import axios from "axios";
import { getUser } from "../helpers/userManager.util";
import queryString from 'query-string';
const baseURL = "https://rms-api.huce.edu.vn";

const privateClient = axios.create({
    baseURL,
    paramsSerializer: {
        encode: params => queryString.stringify(params)
    }
})

privateClient.interceptors.request.use(async config => {
    let signedInUser = getUser();
    let _config = { ...config };
    _config.headers["Content-Type"] = "application/json";
    _config.headers.Authorization = `Bearer ${signedInUser?.access_token}`;
    return _config;
})

privateClient.interceptors.response.use(response => {
    if (response && response.data)
        return response.data;
    return response;
}, error => {
    throw error.response.data;
});

export default privateClient;