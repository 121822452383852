import {
  Box,
  Button,
  Container,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import { getUser } from "../../../utils/helpers/userManager.util";
import { listMenuAdmin, listMenuStudent } from "../../../contstants/menu";

const MenuItem = styled(Box)({
  color: "white",
  padding: "0px 18px",
  fontSize: 16,
  cursor: "pointer",
  position: "relative",
  "&:hover": {
    color: "#d3ad41",
  },
  "&:hover div": {
    display: "block",
  },
});

const ListSubMenu = styled(Box)({
  display: "none",
  position: "absolute",
  background: "white",
  cursor: "pointer",
  zIndex: 1,
  backgroundColor: "#002a5c",
  top: "100%",
});

const SubMenuItem = styled(Box)({
  padding: "0px 18px",
  fontSize: 16,
  color: "white",
  whiteSpace: "nowrap",
  borderBottom: "1px solid #fff",
  "&:hover": {
    color: "#d3ad41",
  },
});

function Menu() {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [list, setList] = useState([]);
  useEffect(() => {
    let userLocal = getUser();
    setUser(userLocal);
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    if (user?.username) {
      user?.role === "Student"
        ? setList(listMenuStudent)
        : setList(listMenuAdmin);
    } else {
      setList([]);
    }
  }, [user]);

  return (
    <Box bgcolor={"#08406f"}>
      {
        user ?
          <Container>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              // minHeight={30}
              // paddingTop={"8px"}
              paddingBottom={"14px"}
            >
              <Box display={"flex"}>
                {list?.map((menu, index) => (
                  <MenuItem key={index} onClick={menu.path ? () => navigate(menu?.path) : () => { }}>
                    {menu.name}
                    {
                      menu.sub ?
                        <ListSubMenu>
                          {menu?.sub?.map((subMenu, index) => (
                            <SubMenuItem
                              onClick={() => navigate(subMenu?.path)}
                              key={index}
                            >
                              {subMenu.name}
                            </SubMenuItem>
                          ))}
                        </ListSubMenu>
                        : ""
                    }

                  </MenuItem>
                ))}
              </Box>
              {user && (
                <Box
                  display={"flex"}
                  gap={4}
                  alignItems={"center"}
                  sx={{ cursor: "pointer" }}
                >
                  <Box
                    display={"flex"}
                    gap={1}
                    alignItems={"center"}
                    sx={{ cursor: "pointer" }}
                  >
                    <PersonIcon sx={{ color: "#d3ad41" }} />
                    <Typography
                      variant="subtitle1"
                      color={"#d3ad41"}
                      fontWeight={"bold"}
                    >
                      {user?.name}
                    </Typography>
                  </Box>
                  <Button
                    color="error"
                    variant="contained"
                    size="small"
                    onClick={handleLogout}
                  >
                    Đăng xuất
                  </Button>
                </Box>
              )}
            </Box>
          </Container>
          : ""
      }

    </Box>
  );
}

export default Menu;
