import axios from "axios";
import queryString from 'query-string';
const baseURL = "https://rms-api.huce.edu.vn";

const publicClient = axios.create({
  baseURL,
  paramsSerializer: {
      encode: params => queryString.stringify(params)
  }
})

publicClient.interceptors.request.use(async config => {
  let _config = { ...config };
  // _config.headers["Content-Type"] = "application/json";
  return _config;
})

publicClient.interceptors.response.use(response => {
  if (response && response.data)
      return response.data;
  return response;
}, error => {
  throw error.response.data;
});

export default publicClient;