import React from "react";
import { Stack } from "@mui/material";
import DangKyChuyenTiepCardView from "../../modules/student/dang-ky-chuyen-tiep/components/DangKyChuyenTiepCardView";
import MainLayout from "../../common/components/layout/MainLayout";

function DangKyChuyenTiepPage() {
  return (
    <MainLayout>
      <Stack height={"100%"}>
        <DangKyChuyenTiepCardView></DangKyChuyenTiepCardView>
      </Stack>
    </MainLayout>
  );
}

export default DangKyChuyenTiepPage;
