import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { getUser } from '../../utils/helpers/userManager.util';
const AuthComponent = ({ children }) => {
    //khai báo
    const navigate = useNavigate();
    const [timeout, setTimeout] = useState(false);
    //End khao báo
    //Use effect
    useEffect(() => {
        let user = getUser();
        if (user?.expirationTime < new Date()) {
            setTimeout(true);
            localStorage.clear();
            navigate("/");
            window.location.reload();
        }
    }, [navigate]);
    //End Use effect

    return timeout ? '' : children;
}

export default AuthComponent