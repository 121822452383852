import privateClient from "../../../common/utils/api/privateClient";

const chuongTrinhDaoTaoEndpoint = {
    fetchList: "/api/chuong-trinh-dao-tao"
}
export {
    chuongTrinhDaoTaoEndpoint
}
const chuongTrinhDaoTaoApi = {
    fetchList: (params, callback) => {
        privateClient.post(chuongTrinhDaoTaoEndpoint.fetchList, params)
        .then((res) => {
          if (callback) callback(res);
        })
        .catch((err) => {
          if (callback) callback(err);
        });
    }
}
export default chuongTrinhDaoTaoApi