
import moment from "moment/moment";
const formatDate = (value, type) => {
    if (!value) return "";
    switch (type) {
        case 1:
            return moment(new Date(value)).format("DD/MM/YYYY")
        default:
            return ""
    }
}
export {
    formatDate
}