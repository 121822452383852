export const listMenuAdmin = [
  {
    name: "Quản lý đăng ký", path: "/quan-ly-dang-ky",
  },
];

export const listMenuStudent = [
  {
    name: "Đăng ký chuyển tiếp", path: "/dang-ky-chuyen-tiep",
  },
];
