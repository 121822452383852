import { Box, Typography } from "@mui/material";
import React from "react";

function Footer() {
  return (
    <>
      <Box
        bgcolor={"#08406f"}
        padding={2}
        textAlign={"center"}
        position={"fixed"}
        bottom={0}
        left={0}
        right={0}
      >
        <Typography color={"#fff"}>
          ©Bản quyền thuộc về trường Đại học Xây Dựng Hà Nội.
        </Typography>
        <Typography color={"#fff"}>
          Quản lý bởi Phòng Quản lý đào tạo. Số ĐT: 024.38691300. Email: daotao@huce.edu.vn
        </Typography>
      </Box>
    </>
  );
}

export default Footer;
