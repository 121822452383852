import './PageLoading.css';

const PageLoading = () => {
    return (
        <div className={"loaderWrapper"}>
            <div className={"loader"}></div>
        </div>
    )
}

export default PageLoading;