import { Box, Stack } from "@mui/material";
import React from "react";

const NotFoundPage = () => {
  return (
    <Stack justifyContent={"center"} alignItems={"center"} height={"100vh"}>
      <Box
        src={"/img/404.png"}
        component={"img"}
        width={"50vw"}
        height={"auto"}
        sx={{ objectFit: "cover", overflow: "hidden" }}
      />
    </Stack>
  );
}
export default NotFoundPage;
